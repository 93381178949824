<template>    
    <b-form>
        <div class="form-group row">
            <label for="id_username">{{ $t("login.email") }}</label>
            <input class="form-control" v-model="username" type="text" required autofocus />
        </div>
        <div class="form-group row">
        <label for="id_password">{{ $t("login.password") }}</label>
            <input class="form-control" v-model="password" type="password" required />
        </div>
        <div class="form-group row">
            <button @click.prevent="login" class="btn btn-lg btn-primary btn-block btn-signin" type="submit">
                {{ $t("button.login") }}
            </button>
        </div>

        <div class="form-group row">
            <button @click.prevent="$ebus.$emit('nav:reset-password-request')" class="btn btn-lg btn-link btn-block btn-forgotten-password">
                {{ $t("button.forgottenPassword") }}
            </button>
        </div>
    </b-form>
</template>

<script>
    import {CONFIG} from '../config.js'

    export default {
        data: function() {
            return {
                username: null,
                password: null
            }
        },
        methods: {
            login: function() {
                var self = this;

                this.$request("post", CONFIG.api.endpoints.token,
                    function(response) {
                        var token = response.data[0]["token"];
                        self.$store.commit('setToken', token);
                        self.$store.commit('setTokenExpiresIn', Date.now() + CONFIG.api.tokenExpiration);
                        self.$store.commit('setLoggedUser', self.username);

                        var path = self.$store.state.tokenExpiredBackwardRoute || CONFIG.afterLoginRoute;
                        self.$router.push({ path: path });
                    },
                    {
                        data:
                        {
                            "login_": this.username,
                            "domain_": CONFIG.api.tokenDomain,
                            "password_": this.password
                        },
                        headers: 
                        {
                            'Content-Profile': 'platform'
                        }
                    }
                )
            }
        }
    }
</script>

<style scoped lang="scss">
</style>