<template>
    <b-container class="login">
        <b-row class="justify-content-center">
            <b-col cols="11" lg="4" xl="4">
                <Login />
            </b-col>
        </b-row>    
    </b-container>
</template>

<script>
    import Login from '@/components/Login.vue'

    export default {
        name: 'login',
        components: {
            Login
        }
    }
</script>

<style scoped lang="scss">   
    @import 'src/scss/main';

    .login {
        margin-top: $container-top-margin;
    }
</style>